/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    all_functions_run: false,
    function_total: 9,
    functions_run: 0,
    alerts: {
        show:     false,
        text:     null,
        type:     null,
    },
    loader: false,
    standardLoader: false,
    alertBS: {
        error: false,
        text: null
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.TOGGLE_SYSTEM_ALERT:

            return {
                ...state,
                alerts: {
                    show: action.payload.boolean,
                    text: action.payload.text,
                    type: action.payload.type,
                }
            }

        case actionTypes.TOGGLE_SYSTEM_LOADER:

            return {
                ...state,
                loader: action.payload,
            }

        case actionTypes.TOGGLE_STANDARD_LOADER:

            return {
                ...state,
                standardLoader: action.payload,
            }

        case actionTypes.TOGGLE_SYSTEM_ALERT_BS:

            if(action.payload && action.payload.error !== undefined && action.payload.text) {

                return {
                    ...state,
                    alertBS: {
                        error: action.payload.error,
                        text: action.payload.text,
                    }
                }

            } else {

                return {
                    ...state,
                    alertBS: null
                }

            }

        default:

            return state;

    }

}

export default reducer;
