import React from "react";
import { Link } from "react-router-dom";
import keys from 'keys';

import { UncontrolledCollapse, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const MainNav = () => {

	return (
		// <Navbar className="navbar-horizontal navbar-dark" expand="lg">
		// 	<Container>

		// 		<NavbarBrand tag={Link} to="/" >
		// 			<img alt="..." src={keys.LOGO} style={{width: 250, height: 'auto'}} />
		// 			<span className="ml-3">
		// 				{/* Donated Today: <span className="text-yellow text-underline">$875,003</span>  */}
		// 			</span>

		// 		</NavbarBrand>

		// 		<button
		// 			aria-controls="navbar-default"
		// 			aria-expanded={false}
		// 			aria-label="Toggle navigation"
		// 			className="navbar-toggler"
		// 			data-target="#navbar-default"
		// 			data-toggle="collapse"
		// 			id="navbar-default"
		// 			type="button"
		// 		>
		// 			<span className="navbar-toggler-icon" />
		// 		</button>

		// 		<UncontrolledCollapse navbar toggler="#navbar-default">
		// 			<div className="navbar-collapse-header">
		// 				<Row>
		// 					<Col className="collapse-brand" xs="6">
		// 						<Link to="/">
		// 							<img alt="..." src={keys.LOGO} />
		// 						</Link>

		// 					</Col>
		// 					<Col className="collapse-close" xs="6">
		// 						<button
		// 							aria-controls="navbar-default"
		// 							aria-expanded={false}
		// 							aria-label="Toggle navigation"
		// 							className="navbar-toggler"
		// 							data-target="#navbar-default"
		// 							data-toggle="collapse"
		// 							id="navbar-default"
		// 							type="button"
		// 						>
		// 						<span />
		// 						<span />
		// 						</button>
		// 					</Col>
		// 				</Row>
		// 			</div>
		// 			<Nav className="ml-lg-auto" navbar>

		// 				<NavItem>
		// 					<NavLink className="nav-link-icon" href="#pablo" onClick={e => e.preventDefault()} >
		// 						<span className=""> Services </span>
		// 					</NavLink>
		// 				</NavItem>
		// 				<NavItem>
		// 					<NavLink className="nav-link-icon" href="#pablo" onClick={e => e.preventDefault()} >
		// 						<span className=""> Contact Us </span>
		// 					</NavLink>
		// 				</NavItem>
		// 				{/* <NavItem>
		// 					<NavLink className="nav-link-icon" href="#pablo" onClick={e => e.preventDefault()} >
		// 						<span className=""> Charity </span>
		// 					</NavLink>
		// 				</NavItem>
		// 				<NavItem>
		// 					<NavLink className="nav-link-icon" href="#pablo" onClick={e => e.preventDefault()} >
		// 						<span className=""> About </span>
		// 					</NavLink>
		// 				</NavItem>

		// 				<NavItem>
		// 					<NavLink className="nav-link-icon" href="#pablo" onClick={e => e.preventDefault()} >
		// 						<i className="ni ni-favourite-28" />
		// 						<span className="nav-link-inner--text d-lg-none"> Discover </span>
		// 					</NavLink>
		// 				</NavItem>

		// 				<NavItem>
		// 					<NavLink className="nav-link-icon" href="#pablo" onClick={e => e.preventDefault()} >
		// 						<i className="ni ni-notification-70" />
		// 						<span className="nav-link-inner--text d-lg-none">Profile </span>
		// 					</NavLink>
		// 				</NavItem>

		// 				<UncontrolledDropdown nav>

		// 					<NavLink
		// 						aria-expanded={false}
		// 						aria-haspopup={true}
		// 						className="nav-link-icon"
		// 						data-toggle="dropdown"
		// 						href="#pablo"
		// 						id="navbar-default_dropdown_1"
		// 						onClick={e => e.preventDefault()}
		// 						role="button"
		// 					>
		// 						<i className="ni ni-settings-gear-65" />
		// 						<span className="nav-link-inner--text d-lg-none">Settings</span>
		// 					</NavLink>

		// 					<DropdownMenu
		// 						aria-labelledby="navbar-default_dropdown_1"
		// 						right
		// 					>
		// 						<DropdownItem href="#pablo" onClick={e => e.preventDefault()}>Action</DropdownItem>
		// 						<DropdownItem href="#pablo" onClick={e => e.preventDefault()} >Another action</DropdownItem>
		// 						<DropdownItem divider />
		// 						<DropdownItem href="#pablo" onClick={e => e.preventDefault()} >Something else here</DropdownItem>
		// 					</DropdownMenu>

		// 				</UncontrolledDropdown> */}
		// 			</Nav>
		// 		</UncontrolledCollapse>
		// 	</Container>
		// </Navbar>
		<Container>
			<h1 className="display-4 text-center text-yellow mb-0 py-3">Fairmax Bankruptcy Consulting LLC</h1>
		</Container>
	)

}

export default MainNav;