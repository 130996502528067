import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar/index.js";
import routes from "./routes";

class Dashboard extends React.Component {
    state = {
        loaded: false,
        showMobileNav: false
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    toggleSidenav = e => {

        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");   
        }

    };

    setScreenSize = () => {

        const { device } = this.props

        if(!device.isLG) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
        }

    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setScreenSize)
    }

    componentDidMount = async () => {



        this.setScreenSize()

        window.addEventListener('resize', this.setScreenSize)

        this.setState({loaded: true})

    }

    render() {

        const { redirect, loaded, showMobileNav } = this.state

        if(redirect) { return <Redirect to={redirect} /> }
        if(!loaded) return <div />

        return (
            <div className="">

                {/* <div className="wave" /> */}

                <div className="" ref="mainContent" onClick={this.closeSidenav} >

                    <Navbar {...this.props} showMobileNav={showMobileNav} toggleSidenav={this.toggleSidenav} />

                    <Switch>
                        {this.getRoutes(routes)}
                        <Redirect from="*" to="/" />
                    </Switch>

                    <Footer />

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
	return {
	  viewing_user: state.auth.viewing_user,
	  device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(Dashboard);
