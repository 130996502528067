/*
Documentation

This file holds all the socket events to listen for within this system
this should correspond exactly to /server/sockets/socketEvents.js in the server
code base of this project

*/

export const JOIN_COMPANY_ROOM        = 'JOIN_COMPANY_ROOM';
export const LEAVE_ROOM               = 'LEAVE_ROOM';

export const APPOINTMENT_CREATED      = 'APPOINTMENT_CREATED';
export const APPOINTMENT_UPDATED      = 'APPOINTMENT_UPDATED';
export const APPOINTMENT_DELETED      = 'APPOINTMENT_DELETED';

export const BOOKING_PAGE_CREATED    = 'BOOKING_PAGE_CREATED';
export const BOOKING_PAGE_UPDATED    = 'BOOKING_PAGE_UPDATED';
export const BOOKING_PAGE_DELETED    = 'BOOKING_PAGE_DELETED';

export const CONTACT_CREATED          = 'CONTACT_CREATED';
export const CONTACT_UPDATED          = 'CONTACT_UPDATED';
export const CONTACT_DELETED          = 'CONTACT_DELETED';

export const CONTACT_EVENTS_ADDED     = 'CONTACT_EVENTS_ADDED';

export const EMAIL_CREATED            = 'EMAIL_CREATED';

export const TEXT_CREATED             = 'TEXT_CREATED';

export const LOGOUT                   = 'LOGOUT';