/*
Documentation

this function needs to run when the app loads
when it is finished it returns true if all went well or false if it did not

*/

import { setViewingUser } from 'store/functions/auth/auth';
import { setDeviceListener } from 'store/functions/device/device'

import socket from 'sockets';
import { LOGOUT } from 'sockets/events';
import store from 'store';

const onLogout = (data) => { 

    const { user_id } = data;
    const state = store.getState();

    const viewing_user = state.auth.viewing_user;

    // if the user who just logged out matches the logged in
    // user on the current page log them out. This means when
    // a user logs out in one tab ALL open tabs are logged out.
    if(viewing_user && viewing_user._id === user_id) {
        window.location.href = "/auth/logout"  
    }

}

const init = () => new Promise (async resolve => {

     // prevent accidental dropping of files into the system
     window.addEventListener("dragover",function(e){ e.preventDefault(); },false);
     window.addEventListener("drop",function(e){ e.preventDefault(); },false);
    // close all user tabs on logout
    socket.on(LOGOUT, onLogout);
    // listen for changes in screen size, set device, etc
    setDeviceListener()

    //run all promises that need to fire before the app loads here
    // const values = await Promise.all([
    //     setViewingUser(),
    // ])

    let errors = 0;

    // make sure all required calls pass
    // values.forEach(v => { if(v.success === false) { errors++; } })

    return resolve(errors ? false : true)

})

export default init