/*
Documentation

export a client side instance of socket.io to use throughout the application

*/

import keys from '../keys'
import socket from 'socket.io-client';

const websocket = socket(keys.API_URL, {
    transports: ['websocket'],
    autoConnect: true
});

export default websocket