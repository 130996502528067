import LOGO from 'assets/img/brand/ConsultingLogo.png'

let keys = {
    LOGO,
    COMPANY: 'New Software',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'prod url') {

    keys = {

        API_URL                   : '',
        SYSTEM_API_KEY            : 'architeck-',
        STRIPE_PUBLISHABLE_KEY    : '',

    }

//using staging keys
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        API_URL                   : 'https://api.pacerjet.com',
        SYSTEM_API_KEY            : 'architeck-8367152445',
        STRIPE_PUBLISHABLE_KEY    : '',

    }

//using development keys
} else {

    keys = {

        ...keys,

        API_URL                   : 'http://localhost:5000',
        SYSTEM_API_KEY            : 'architeck-',
        STRIPE_PUBLISHABLE_KEY    : '',


    }

}

export default keys
