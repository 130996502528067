
import React from "react";

import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import keys from 'keys';

import { Container, Row, Col } from "reactstrap";

import bg from 'assets/img/backgrounds/test5.jpg'

class Home extends React.Component {
  
    state = {
        collapseOpen: false,
    };



    componentDidMount = () => {

    }
  
    render() {


        return (
            <div>


                <div className="z-depth-5" style={{backgroundImage: `url("${bg}")`, backgroundPosition: 'center', backgroundSize: 'cover'}}>

                    <div className="py-9 text-center" style={{background: 'rgba(0,0,0,.5)'}}>
                        <Container>
                            <h1 className="mb-0 display-1">National Bankruptcy Coordination</h1>
                            <p className="display-4 font-weight-bold text-italic mb-0 text-yellow">Let our experts help guide your firm in any class action law suit.</p>
                        </Container>
                    </div>
                </div>



                <div className="bg-yellow py-4 text-center">
                    <Container>
                        <h2 className="text-dark display-3 mb-0"><i className="fas fa-mobile mr-2 " /> Call Us: (313) 918-1706</h2>
                    </Container>
                </div>

                <div className="bg-full-black py-2 text-center z-depth-3">
                    <Container>
                        <p class="text-sm mb-0">For all email inquires please direct your messages to <b>info@fairmaxconsulting.com </b></p>
                    </Container>
                </div>
                <div className="py-7">
                    <Container>
                        <h2 className="display-2">Who We Are</h2>
                        <Row>
                            <Col xs={12} lg={10}>
                                <p>The filing of a bankruptcy by a mass tort claimant can involved nuanced and complicated issues with settlement and disbursement with at times severe consequences. Fairmax Bankruptcy Consulting LLC specializes in the efficient and comprehensive navigation of the bankruptcy process for claimants and their representatives</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <hr  className="my-0" />

                <div className="bg-full-black py-7 z-depth-3">
                    <Container>
                        <Row> 

                            <Col lg={7} className="align-self-center  text-md-down-left">
                                <h2 className="display-2 text-yellow">SCRUBBING &<br /> MONITORING</h2>
                            </Col>
                            
                            <Col lg={5} className="align-self-center text-right text-md-down-left">
                                <p className="text-white">Fairmax Bankruptcy Consulting's software not only searches for and discovers bankruptcy filings of mass tort claimants, but also provides the ability to monitor claimant lists for subsequent bankruptcy filings.</p>
                            </Col>

                        </Row>
                    </Container>
                </div>
               
                <div className="py-7 z-depth-3">
                    <Container>
                        <Row> 


                            <Col lg={6} className="align-self-center text-right text-md-down-left order-lg-2">
                                <h2 className="display-2 text-yellow">PROFESSIONAL ANALYSIS &<br /> COORDINATION</h2>
                            </Col>


                            <Col lg={6} className="align-self-center text-md-down-left order-lg-1">
                                <p className="text-white">Our team handles the entire bankruptcy process on behalf of Plaintiff counsel or the Claims administrator from start to finish. That includes all communication, monitoring, document and pleading review and analysis, and full coordination with all bankruptcy stakeholders</p>
                            </Col>

                        </Row>
                    </Container>
                </div>

                <div className="bg-full-black py-7 z-depth-3">
                    <Container>
                        <Row>

                            <Col lg={7} className="align-self-center  text-md-down-left">
                                <h2 className="display-2 text-yellow">COMPREHENSIVE KNOWLEDGE</h2>
                            </Col>

                            <Col lg={5} className="align-self-center text-right text-md-down-left">
                                <p className="text-white">Our on staff attorneys have built a network of relationships with bankruptcy attorneys and trustees throughout that nation. This eliminates surprises or delay no matter where a claimant filed bankruptcy.</p>
                            </Col>

                        </Row>
                    </Container>
                </div>


            </div>
           
           
        );
    }

}



export default Home;