/*
Documentation

this is the footer for the dashboard layout

*/

import React from "react";
import { Link } from 'react-router-dom';
import { Col, Container, Nav, NavItem, Row } from "reactstrap";
import keys from 'keys'

const Footer = () => (
    // <div id="dashboard-footer" className="footer-wrapper">
    //     <Container>
    //         <footer className="footer">
    //             <Row className="align-items-center justify-content-lg-between">

    //                 <Col lg="6">
    //                     <div className="copyright text-center text-lg-left text-muted">
    //                         © {new Date().getFullYear()}{' '}
    //                         {keys.COMPANY}
    //                         {' '}&mdash;{' '}
    //                         <a className="font-weight-bold ml-1" href="https://architeck.io" rel="noopener noreferrer" target="_blank">
    //                             Software Built By Architeck
    //                         </a>
    //                     </div>
    //                 </Col>

    //                 <Col lg="6">

    //                     <Nav className="nav-footer justify-content-center justify-content-lg-end">

    //                         <NavItem>
    //                             <Link className="nav-link" to="/dashboard/settings">My Profile</Link>
    //                         </NavItem>

    //                         <NavItem>
    //                             <Link className="nav-link" to="/dashboard/settings">Terms Of Service</Link>
    //                         </NavItem>

    //                         <NavItem>
    //                             <Link className="nav-link" href="logout" to="/auth/logout">Logout</Link>
    //                         </NavItem>

    //                     </Nav>

    //                 </Col>

    //             </Row>
    //         </footer>

    //     </Container>
    // </div>

    <div>

        {/* <div className="bg-yellow text-center">
            <Container>

                <Row>
                    <Col lg={7}>
                        <h2 className="display-2 mt-4" style={{color: 'black'}}>A letter from our founder</h2>
                        <p style={{color: 'black'}}>I’ve been handling bankruptcies for people just like you for more than a decade. I’ve watched the laws change frequently, and I’ve even co-authored a book that practicing bankruptcy attorneys use to learn about Credit Report Law.

The effects that this pandemic is having on people are unprecedented. If you are not wealthy, like many of us, you likely don’t know how you will survive the next few months without negatively impacting your credit, or worse, losing your house.

That is why Fairmax Law was started, to help people identify their options when they’re facing hardships.

People who are unemployed, or even under-employed, qualify for debt-relief, and now is the time to act. There is no need for endless worrying and hiding from creditors. Call us today so one of our qualified attorneys can talk you through your options.

Thank you for considering us as your guides through your debt-relief journey.</p>
                    </Col>
                    <Col lg={5}>
                        <img style={{position: 'relative', top: 15}} src="https://fairmaxlaw.com/static/media/MikeJaafarYellow.68f81fd6.jpg" className="w-100" />
                    </Col>
                </Row>

            </Container>
        </div> */}

        <div className="bg-full-black py-4 text-center border-top">
            <Container>
                <p classnames="text-sm text-dark">The information on this website is for general information purposes only. Nothing on this site should be taken as legal advice for any individual case or situation. This information is not intended to create, and receipt or viewing does not constitute, an attorney-client relationship.</p>
                
                <p classnames="text-sm mb-0" style={{marginBottom: 0}}>1 Parklane Blvd, Suite 729 E</p>
                <p classnames="text-sm mt-0">Dearborn, MI 48126</p>

                <p classnames="text-sm text-dark">© 2021 Fairmax Bankruptcy Consuling - All Rights Reserved</p>
            </Container>
        </div>

    </div>
  
)

export default Footer;
