/*
Documentation

this page holds all the routes for the dashboard layout

*/


import Home  from '../pages/Home';

const routes = () =>  [

    {
        path: "/",
        component: Home,
    },

]

export default routes;
