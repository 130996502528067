/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    navbar: {
        title: 'Contacts',
        breadcrumb_1: {
            title: 'all',
            link: '/dashboard/contacts'
        },
        breadcrumb_2: {
            title: undefined,
            link: undefined
        },
        breadcrumb_3: {
            title: undefined,
            link: undefined
        }
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_NAVBAR_STATE:

            return {
                ...state,
                navbar: action.payload
            }

        default:

            return state;

    }

}

export default reducer;
